<div class="container">
  <div *ngIf="isSidebarVisible" class="sidebar">
    <div class="container-left">
      <div class="logo">
        <div class="logo-left">
          <img class="logoimg" src="../../../assets/images/logo.png" alt="LSRT Logo" />
          <span class="logoletter">LSRT</span>
        </div>
        <div (click)="cancel()" class="cancel"><img src="../../../assets/icons/x.svg" alt=""></div>
      </div>
      <div class="content">
        <p class="chapter" *ngFor="let chapter of chapters" (click)="scrollTo(chapter.id)"
          [class.active]="activeSection === chapter.id">
          <span class="chapter-text">{{ chapter.tittle }}</span>
        </p>
      </div>
    </div>
  </div>
  <div #header class="legacy-description-div">
    <div class="legacy-description">
      <div *ngIf="!isSidebarVisible" class="hamburger-menu" (click)="toggleSidebar()">
        <div>
          <img src="../../../assets/icons/bold/humburger.svg" alt="">
        </div>
      </div>
      <p>Legal / Privacy Policy</p>
      <div class="logo-right">
        <img src="../../../assets/images/certified.png" alt="Certified" />
      </div>
    </div>
  </div>
  <div class="container-right">
    <div class="main">
      <div class="main-body">
        <div class="privacy-date-div">
          <div>
            <h2>Privacy Policy</h2>
          </div>
          <div class="privacy-date-content">
            <span>Updated:</span>
            <span class="calender-icon"><img src="../../../assets/calender.svg" alt=""></span>
            <span>26th July 2024</span>
          </div>
        </div>
        <div #content class="section" *ngFor="let chapter of chapters" [id]="chapter.id">
          <h2 class="section-chapter" *ngIf="chapter.tittle !== ''">{{ chapter.tittle }}</h2>
          <div class="section-content">
            <p>{{ chapter.content }}</p>
            <div *ngIf="chapter.li && chapter.li.length > 0">
              <ul>
                <li *ngFor="let item of chapter.li">{{ item }}</li>
              </ul>
            </div>
            <p *ngIf="chapter.footer !== ''">{{ chapter.footer }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
