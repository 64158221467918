@if(inputData?.message){
  <div class="toast-container" 
    [ngClass]="
    inputData?.position == 'leftTop'
    ? 'toast-container leftTop'
    : inputData?.position == 'rightTop'
    ? 'toast-container rightTop'
    : inputData?.position == 'leftBottom'
    ? 'toast-container leftBottom'
    : 'toast-container rightBottom'"
  > 
    <div class="inner-container" >
      <div class="toast-body">
        <div class="inner-body-div">
          <span class="icon">
            @if (inputData.title?.toLowerCase().includes('error')) {
              <img src="../../../assets/icons/cloud-cross.svg">
            }@else if (inputData.message?.toLowerCase().includes('notification has been enabled') || inputData?.push == 'push') {
            <span class="active-img-span">
              <img src="../../../assets/icons/enable-notification.svg" alt="">
            </span>
          }@else if (inputData.message?.toLowerCase().includes('notification has been disabled')) {
            <span class="inactive-img-span">
              <img src="../../../assets/icons/disable-notification-bing.svg" alt="">
            </span>
          }@else{
            <img src="../../../assets/icons/toast-check-icon.svg">
            }
          </span>
          <div class="msg-div">
            <div class="title">{{inputData?.title}}</div>
            <div class="msg" >{{inputData?.message}}</div>

          </div>
        </div>
        <img class="action-icon" (click)="closeToast()" src="../../../assets/icons/close-icon.svg">
    
      </div>
    </div>
  </div>
} 