import { Component, Input } from '@angular/core';
import { ExportService } from '../../services/export.service';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-export-modal',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './export-modal.component.html',
  styleUrl: './export-modal.component.scss'
})
export class ExportModalComponent {
  @Input() exportData: any;
  // public updatedToastDate: ToastStoreDto;

  ngOnInit(): void {}

  constructor(public exportService: ExportService) {}

  ngOnChanges() {
  }

  closeExportModal() {
    this.exportService.clearStore();
  }
}
