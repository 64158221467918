import { Component, OnInit } from '@angular/core';
import { ToastService } from '../../toast';
import { Router, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { ButtonWrapperComponent } from '../../components/button-wrapper/button-wrapper.component';
import { InputFieldsComponent } from '../../components/input-fields/input-fields.component';
import { HttpErrorResponse } from '@angular/common/http';
import {
  FormBuilder,
  FormControl,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';

import { DropDownCustomComponent } from '../../components/drop-down/drop-down-custom.component';
import { UserListService } from '../../services/user-list.service';
import { LoaderComponent } from '../../components/loader/loader.component';

@Component({
  selector: 'app-delete-user-account',
  standalone: true,
  imports: [
    CommonModule,
    ButtonWrapperComponent,
    InputFieldsComponent,
    ReactiveFormsModule,
    FormsModule,
    DropDownCustomComponent,
    LoaderComponent,
    RouterModule,
  ],
  templateUrl: './delete-user-account.component.html',
  styleUrls: ['./delete-user-account.component.scss'],
})
export class DeleteUserAccountComponent {
  showLoader = true;
  userDeleted = false;
  successfull = false;
  copyrightYear: any = new Date();
  email: string = '';
  password: string = '';
  reasons: string = '';

  formData = this.fb.group({
    email: new FormControl('', [
      Validators.email,
      Validators.required,
      Validators.pattern(/^[^@]+@[^@]+\.[^@]+$/),
    ]),
    password: new FormControl('', [
      Validators.required,
      Validators.minLength(6),
    ]),
    Reason: new FormControl<string>('', [Validators.required, Validators.min(5)]),
  });

  errorMessages: Record<string, string> = {
    required: 'This field is required',
    email: 'The email is invalid',
    pattern: 'The email is invalid',
  };

  constructor(
    private fb: FormBuilder,
    private usersService: UserListService,
    public router: Router,
    public toastService: ToastService,
  ) {}

  goBack() {
    window.history.back();
  }
  getMail(email: string) {
    this.email = email;
  }
  getPassword(password: any) {
    this.password = password;
  }
  getReason(reasons: any) {
    this.reasons = reasons;
  }

  deleteUserAccount() {
    this.showLoader = true;
    const payload = {
      email: this.email,
      password: this.password,
    };
    this.usersService.deleteuseraccount(payload).subscribe({
      next: () => {
        this.email = '';
        this.password = '';
        this.reasons = "";
        this.showLoader = false;
        this.successfull = true;
      },
      error: (err: HttpErrorResponse) => {
        this.showLoader = false;
        this.successfull = false;
      },
    });
  }

  getErrorMessage(controlName: string): string {
    const control = this.formData.get(controlName);
    if (control?.hasError('required')) {
      return this.errorMessages['required'];
    } else if (control?.hasError('email')) {
      return this.errorMessages['email'];
    } else if (control?.hasError('pattern')) {
      return this.errorMessages['pattern'];
    }
    else if (control?.hasError('min')) {
      return this.errorMessages['min'];
    }
    return '';
  }

  errorHandler(error: HttpErrorResponse) {
    this.successfull = false;
  }
}
