import { CommonModule } from '@angular/common';
import { Component, output } from '@angular/core';
import { ButtonWrapperComponent } from "../../components/button-wrapper/button-wrapper.component";
import { InputFieldsComponent } from "../../components/input-fields/input-fields.component";
import { AuthService } from '../../services/auth/auth.service';
import { SignInDto, ForgotPasswordDTO } from '../../dto/signin-dto';
import { HttpErrorResponse } from '@angular/common/http';
import { Route, Router, RouterModule } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';

import { environment } from '../../../environments/environment.development';
import { LoaderComponent } from '../../components/loader/loader.component';
import { FooterComponent } from '../footer/footer.component';
import { ToastService } from '../../toast';

@Component({
    selector: 'app-sign-in',
    standalone: true,
    templateUrl: './sign-in.component.html',
    styleUrl: './sign-in.component.scss',
    imports: [CommonModule, ButtonWrapperComponent, InputFieldsComponent, LoaderComponent, RouterModule, FooterComponent]
})
export class SignInComponent {
  
 copyrightYear: any = new Date();
 nav: number = 1;
 siginDto = new SignInDto;
 email: string = '';
 password: string = '';
 invalidCred: any;
 public validationFlag:boolean = true;
 public showLoader:boolean = false;
 public errMsg: string = "";
 signInApp = this.fb.group({
  email: [
    '',
    [
      Validators.required,
      Validators.email,
      Validators.pattern(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/),
    ],
  ],
  password: ['', [Validators.required, Validators.minLength(8)]],
});
  public signinPage:boolean = true;
  public resetPswdPage:boolean = false;
  public resetPasswordstep_1:boolean = false;
  public resetPasswordstep_2:boolean = false;
  constructor(private authService: AuthService, private router: Router, private fb: FormBuilder, private toastService:ToastService){

  }

  ngOnInit(){
    this.nav = 1;
  }

  navLink(dir:string){
    if(dir === 'right'){
      if(this.nav < 3){
        this.nav = this.nav+1;
      }else{
        return;
      }
    }else if(dir === 'left'){
      if(this.nav > 1){
        this.nav = this.nav-1;
      }else{
        return;
      }
    }
  }

  getformdata(event:any, name:any){
    // event?.stopPropagation();
    if(name == 'email'){
      // console.log(event, '....b')
      this.email = event;
    }
    if(name == 'password'){
      // console.log(event, '....ppp')
      this.password = event;
    }
    if(name=='rest-pswd'){
      this.email = event
    }
  }

  signIn(){
    this.showLoader = true;
    this.signInApp.markAllAsTouched();
    if(this.signInApp.valid){
    const signinData = {
      email: this.email,
      password: this.password
    };
    this.siginDto = signinData;
    // console.log(this.siginDto)
    this.authService.signInUser(this.siginDto).subscribe({
      next: (data: any) => {
        this.showLoader = false;
        this.authService.setUserData(data);
        // console.log(this.router.navigateByUrl("/dashboard"))
        this.router.navigate(["/dashboard"]);
      },
      error: (error: HttpErrorResponse) => {
        this.showLoader = false;
        this.errorHandler(error);
        if(error.error.message == "invalid credentials"){
          this.invalidCred = error.error.message;
        }else if(error.error.message == "User not found"){
          this.invalidCred = error.error.message;
        }else if(error.error.message == "Your user does not have access to this application."){
          // this.toastService.setErrorMessage('User does not have access to this application.');
          this.router.navigate(["/download-app-signin"]);
        }else if(error){
          this.invalidCred = "Something went wrong. Check your credentials";
        }
      },
    });
  }else {
    this.showLoader = false;
      this.signInApp.controls.email.valid;
      if (this.signInApp.controls.email.invalid) {
        const regex = /^[^@]+@[^@]+\.[^@]+$/;
        if (!regex.test(this.signInApp.value.email as string)) {
          return;
        }
        if (this.signInApp.controls.password.invalid) {
        }
      }
    }
  }

  resetPswd(){
    if(this.resetPswdPage){
      this.resetPswdPage = !this.resetPswdPage;
      this.signinPage = !this.signinPage;
      this.resetPasswordstep_1 = !this.resetPasswordstep_1;
    }
    else if(this.signinPage){
      this.signinPage = !this.signinPage;
      this.resetPswdPage = !this.resetPswdPage;
      this.resetPasswordstep_1 = !this.resetPasswordstep_1;

    }
  }
  submitData(){
    let emailPattern = new RegExp(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/);
    if(emailPattern.test(this.email)){
      this.validationFlag = true;
      const url = `${environment.hostUrl}reset-password`
      const payload:ForgotPasswordDTO = {
        email: this.email,
        replyUrl: url
      }
      this.authService.forgotPassword(payload).subscribe({
        next:(data)=>{
          this.resetPasswordstep_1 = !this.resetPasswordstep_1;
          this.resetPasswordstep_2 = !this.resetPasswordstep_2;
          console.log(data);
        }, 
        error:(error:HttpErrorResponse)=>{
          this.errorHandler(error)
          this.errMsg =error.error.message;
          console.log(this.errMsg)
        }
  
      })
    }else{
      this.validationFlag = false;
    }

    
  }
  errorHandler(error: HttpErrorResponse) {
    // return this.toastService.setError(GeneralUtil.errorHandler(error));
    this.authService.errorHandler(error);
  }

}
