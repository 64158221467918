import { CommonModule } from '@angular/common';
import { Component, HostListener, NgModule, Renderer2, OnInit, viewChild, ElementRef, ViewChild, ViewChildren, QueryList } from '@angular/core';
export interface Chapter {
  id: string;
  tittle: string | null;
  content: string | null;
  inner_content: string | null;
  footer: string | null;
  li: string[];
}
@Component({
  selector: 'app-policy-and-privacy',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './policy-and-privacy.component.html',
  styleUrl: './policy-and-privacy.component.scss',
})
export class PolicyAndPrivacyComponent implements OnInit {
  isSidebarVisible: boolean = true;
  chapters: Chapter[] = [
    {
      id: 'introduction',
      tittle: 'Introduction',
      content: `ELSRT (Electronic Laboratory Sample Referral & Tracking System) is an application developed by SeamHealth for the Nigeria Centre for Disease Control (NCDC) and the Institute of Human Virology Nigeria (IHVN). As developers, we are committed to prioritizing the protection of the privacy rights of individuals. This Privacy Policy helps explain the data practices related to the collection, use, and disclosure of data through the App. This Privacy Policy applies to all the services offered via the App, including our website, features, widgets, or online services that are owned and controlled by NCDC and IHVN, as well as any information collected offline and through other means in connection with the services rendered by the App.`,
      inner_content: '',
      li: [],
      footer: '',
    },
    {
      id: 'Purpose',
      tittle: 'Purpose',
      content: `This privacy policy aims to give you information on how we collect and process your personal data through your use of this app, including any data you may provide through this app. It is important that you read this privacy policy together with any other privacy policy, or notice, we may provide on specific occasions when we are collecting or processing personal data about you, so that you are fully aware of how and why we are using your data. This privacy policy supplements other privacy policies and notices and is not intended to override them.`,
      inner_content: '',
      li: [],
      footer: '',
    },
    {
      id: 'Who_Are_We',
      tittle: 'Who Are We',
      content: `We are SeamHealth, a multi-disciplinary healthcare consulting firm and the developers of ELSRT (“the App”), a mobile application designed to enhance the national and subnational specimen referral systems in Nigeria. The App facilitates the efficient and timely transportation and tracking of specimens from collection sites to diagnostic laboratories to rapidly detect priority pathogens and epidemic-prone diseases. By using the App, you consent to the data practices described in this policy.`,
      inner_content: '',
      li: [],
      footer: '',
    },
    {
      id: 'Information_We_Collect',
      tittle: 'Information We Collect',
      content: `In order to provide you with the services offered by our App, we may collect the following information:`,
      inner_content: '',
      li: [
        'Personal Information: Such as your name, email address, phone number, and organization details.',
        'Sample Information: Including but not limited to sample IDs, collection dates, and testing results.',
        'Usage Data: Information about your interactions with the app, such as access times, pages viewed, and other user activities.',
      ],
      footer:
        'We do not collect personal data about you unless you voluntarily provide it to us.',
    },
    {
      id: 'Personal_Data_We_Collect_Automatically_from_You_and/or_Your Device',
      tittle:
        'Personal Data We Collect Automatically from You and/or Your Device',
      content: ``,
      inner_content: '',
      li: [
        'Location Information: General location information inferred from your IP address.',
        'Device Information: Data about the device and software you use to access our services, including IP address, browser type, operating system version, phone carrier and manufacturer, application installations and versions, device identifiers, mobile advertising identifiers, and push notification tokens.',
        'Usage Information: Information about your interactions with our services, such as the content you view, actions you take, features you interact with, and the dates and times of your visits.',
      ],
      footer: '',
    },
    {
      id: 'Purpose_of_the_Data_Collection',
      tittle: 'Purpose of the Data Collection',
      content: `To onboard users to the ELSRT platform, facilitate the specimen referral process, enhance the efficiency and reliability of specimen transportation and tracking, and provide real-time data for managing specimen referrals.`,
      inner_content: '',
      li: [],
      footer: '',
    },
    {
      id: 'How_We_Use_Your_Information',
      tittle: 'How We Use Your Information',
      content: `We use the information we collect for the following purposes:`,
      inner_content: '',
      li: [
        'Monitor and manage specimen referrals.',
        'Improve, personalize, and expand our services.',
        'Ensure efficient transportation, provide real-time data and analytics, and improve response times for detecting and controlling epidemic-prone diseases.',
        'Communicate with you, including customer service and support.',
        'Process and manage laboratory sample referrals and tracking.',
        'Monitor and analyze usage patterns and trends.',
        'Comply with legal obligations and protect our rights.',
      ],
      footer: '',
    },
    {
      id: 'Sharing_Your_Information',
      tittle: 'Sharing Your Information',
      content: `We may share your information with:`,
      inner_content: '',
      li: [
        'Service Providers: Third-party affiliates and partners who perform services on our behalf, such as data analysis and customer support.',
        'Health Authorities: Relevant government health authorities or agencies as required by law or for public health purposes.',
        'Legal Requirements: When required for the functioning of the referral system or to comply with legal and regulatory requirements.',
      ],
      footer: '',
    },
    {
      id: 'Data_Security',
      tittle: 'Data Security',
      content: `We employ encryption, secure access controls, regular security audits, and compliance with relevant data protection measures to safeguard your personal data against unauthorized access, alteration, disclosure, or destruction. However, no method of transmission over the internet or electronic storage is 100% secure. No website, service, mobile application, database, or system is completely secure or “hacker proof.” You are responsible for taking reasonable steps to protect your personal data against unauthorized disclosure or misuse.`,
      inner_content: '',
      li: [],
      footer: '',
    },
    {
      id: 'Your_Data_Rights',
      tittle: 'Your Data Rights',
      content: `As a data subject, you have certain rights regarding your personal data, which include:`,
      inner_content: '',
      li: [
        'If we process your data on the basis of your consent, to withdraw your consent at any time.',
        'To correct your data if it is inaccurate or incomplete.',
        'If we process your data on the basis of your consent or to fulfill a contract, to receive a copy of your personal information that was processed.',
        'To request the erasure (deletion) of your personal data (in certain cases).',
        'To ask us to stop processing (restrict processing) your personal information.',
        'Where we process your data on the basis of your consent or performance of a contract, you can ask us to transfer your personal data to a third party indicated by you.',
      ],
      footer: 'Individuals also have the opportunity to choose whether their personally identifiable data is to be disclosed to a third party or to be used for a purpose other than the purpose it was originally collected or subsequently authorized by the individual. When you request us to remove any of your data that we have on our systems, we will respond as soon as practically possible. If you wish to no longer have your personally identifiable information used or disclosed by our clients or by us in connection with our work for our clients, please contact the client that you interact with directly. Alternatively, you may contact our privacy officer at info@seamhealth.com, and we will pass your request along to our client.',
    },
    {
      id: 'Retention_of_Data',
      tittle: 'Retention of Data',
      content: `We retain your personal data for as long as necessary for the purpose(s) it was collected. Storage of your data is also determined by legal, regulatory, administrative, or operational requirements, which are considered customary in our sector. We retain only the information necessary to comply with legal and regulatory requests for specific data, fulfill necessary business undertakings and auditing prerequisites, address complaints and inquiries, and handle any potential disputes or claims that might arise. Data that is not retained is carefully destroyed when it is identified that it is no longer needed for the purposes for which it was collected.`,
      inner_content: '',
      li: [ ],
      footer: '',
    },
    {
      id: 'Reviewing_Your_Personal_Information',
      tittle: 'Reviewing Your Personal Information',
      content: `In all cases, you can make a request to review and correct your personal data that is collected via our app or that you provide when you contact us by using the contact information stated in this policy. We may take steps to verify your identity before providing you access to your personal information. You can help us to maintain the accuracy of your information by notifying us of any change to your mailing address, phone number, or email address.`,
      inner_content: '',
      li: [ ],
      footer: '',
    },
    {
      id: 'Analytics',
      tittle: 'Analytics',
      content: `We may use third-party service providers to monitor and analyze the use of our service.`,
      inner_content: '',
      li: [
        'Google Analytics: Google Analytics is a web analytics service offered by Google that tracks and reports app traffic. Google uses the data collected to track and monitor the use of our service. This data is shared with other Google services. Google may use the collected data to contextualize and personalize the ads of its own advertising network. You can opt-out of having your activity on the service available to Google Analytics by installing the Google Analytics opt-out browser add-on. The add-on prevents the Google Analytics JavaScript (ga.js, analytics.js, and dc.js) from sharing information with Google Analytics about visits activity. '
       ],
      footer: 'For more information on the privacy practices of Google, please visit the Google Privacy & Terms web page: https://policies.google.com/privacy  ',
    },
    {
      id: 'Children’s_Privacy',
      tittle: 'Children’s Privacy',
      content: `Please note that our services with respect to the App apply to children irrespective of their age. We may have to collect personally identifiable information from children under the age of 18, under the strict supervision and consent of the child’s parent, guardian, or legal custodian.`,
      inner_content: '',
      li: [],
      footer: '',
    },
    {
      id: 'International_Data_Transfers',
      tittle: 'International Data Transfers',
      content: `Where personal data is to be transferred to a country outside Nigeria, we shall put adequate measures in place to ensure the security of such personal data and to ensure that the transfer is done securely and in accordance with Nigerian data protection laws.`,
      inner_content: '',
      li: [],
      footer: '',
    },
    {
      id: 'Changes_to_This_Privacy_Policy',
      tittle: 'Changes to This Privacy Policy',
      content: `We reserve the right to change this Privacy Policy from time to time. We will notify you of any significant changes in the way we treat personal data by sending a notice to the primary email address specified in your account or by placing a prominent notice on our app and/or by updating any privacy information on this page. You are advised to review this Privacy Policy periodically for any changes. The posting of the update notice on our app or our website shall be considered adequate notice to you. Your continued use of the site after such modification will constitute your: a) acknowledgement of the modified Privacy Policy; and b) agreement to abide by and be bound by that policy.`,
      inner_content: '',
      li: [],
      footer: '',
    },
    {
      id: 'Business_or_Asset_Transfer_or_Sale',
      tittle: 'Business or Asset Transfer or Sale',
      content: `In the event that ELSRT (the App) is sold, we may also buy businesses or assets of businesses, or even merge with another business. In such transactions, customer data generally is one of the transferred business assets. If the App or substantially all its assets are transferred, customer information or data may be one of the transferred assets. We will make every effort to provide notice on our site or on the App and/or notify you via email, sent to the most recent email address that you have provided to us, of such change in ownership or control of your personal data.`,
      inner_content: '',
      li: [],
      footer: '',
    },
    {
      id: 'Governing_Law',
      tittle: 'Governing Law',
      content: `This Privacy Policy is governed by the laws of the Federal Republic of Nigeria. You agree to submit to the exclusive jurisdiction of the courts of Nigeria in relation to any dispute, conflict, or claim arising out of or in connection with this Privacy Policy or the use of your personal data.`,
      inner_content: '',
      li: [],
      footer: '',
    },
    {
      id: 'Contact_Us',
      tittle: 'Contact Us',
      content: `All access requests, questions, comments, complaints and other requests regarding the privacy policy should be sent to info@seamhealth.com. `,
      inner_content: '',
      li: [],
      footer: 'We may request additional details from you regarding your complaints and keep record of your requests and resolutions.',
    },
  ];
  
  
  activeSection = '';
  screenWidth: number = window.innerWidth; 

  @ViewChild('header') headerRef: ElementRef | undefined;
  @ViewChildren('content') contentRefs: QueryList<ElementRef> | undefined;

  constructor(private renderer: Renderer2) {}

  ngOnInit(): void {
    this.checkScreenWidth();
  }

  cancel(){
    return this.isSidebarVisible = false
  }
  scrollTo(sectionId: string) {
    const element = this.contentRefs?.find(el => el.nativeElement.id === sectionId)?.nativeElement;
    if (element) {
      const headerOffset = this.headerRef?.nativeElement.clientHeight || 0; // Adjust based on your header

      const elementPosition = element.getBoundingClientRect().top;
      const offsetPosition = elementPosition - headerOffset;

      window.scrollTo({
        top: window.scrollY + offsetPosition, // Use window.scrollY
        behavior: 'smooth'
      });

      this.activeSection = sectionId;

      // Hide the sidebar if on mobile or tablet view
      if (this.screenWidth <= 850) {
        this.isSidebarVisible = false;
      }
    }
  }

  @HostListener('window:scroll', [])
  onScroll() {
    const headerOffset = this.headerRef?.nativeElement.clientHeight || 0; // Adjust based on your header
    let currentSection = '';
    this.contentRefs?.forEach(el => {
      const rect = el.nativeElement.getBoundingClientRect();
      if (rect.top <= headerOffset && rect.bottom >= 0) {
        currentSection = el.nativeElement.id;
      }
    });
    this.activeSection = currentSection;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    this.checkScreenWidth();
  }

  toggleSidebar(): void {
    this.isSidebarVisible = !this.isSidebarVisible;
  }

  private checkScreenWidth(): void {
    this.screenWidth = window.innerWidth;
    this.isSidebarVisible = this.screenWidth > 850;
  }
}
