<div class="container">
    <div class="lft">
      <img src="../../../assets/icons/Rectangle1-bg.svg" alt="" class="first-top left-imgbg">
      <img src="../../../assets/icons/Rectangle2-bg.svg" alt="" class="second-top left-imgbg">
      <div class="header">
        <div class="lft-side">
          <img class="logoimg" src="../../../assets/images/logo.png" alt="">
          <span class="logo-title">LSRT</span>
        </div>
        <div class="right">
          <img src="../../../assets/images/certified.png" alt="">
        </div>
  
      </div>  
      @if (step_1) {
          <div class="body">
              <div class="upper-div">
                <div class="title">Set New Password</div>
                <div class="txt-content">
                  Enter a secure password for your account
                </div>
      
              </div>
              <div class="lower-div">
                <div class="field">
                  <app-input-fields type="password" placeholder="Create Password" 
                    (fieldData)="getData($event, 'password')" [control]="resetPswd.controls.password" >
                  </app-input-fields>
                  <!-- <app-input-fields type="password" placeholder=""  (fieldData)="getData($event, 'password')"></app-input-fields> -->
                  @if(!isPswdMatch){
                    <div class="pswd-hint">
                      <img src="../../../assets/icons/alert-circle.svg" alt="">
                      <span>Password didn't match</span>
                    </div>
                  }
                </div>
                <div class="field">
                  <app-input-fields type="password" placeholder="Confirm Password" 
                    (fieldData)="getData($event, 'cPassword')" [control]="resetPswd.controls.confirmPassword" >
                  </app-input-fields>
                    <!-- <app-input-fields type="password" placeholder="confirm Password"  (fieldData)="getData($event, 'cPassword')"></app-input-fields> -->
                    @if(!isPswdMatch){
                      <div class="pswd-hint">
                        <img src="../../../assets/icons/alert-circle.svg" alt="">
                        <span>Password didn't match</span>
                      </div>
                    }
                  </div>
                <div class="btn">
                  <app-button label="Set Password" (click)="submitData()" ></app-button>
                </div>
              </div>
          </div>
      }
      @else if (step_2) {
        <div class="body-2 extra-css">
          <div class="step-2-upper-div">
            <div class="bck-arr" >
              <img src="../../../assets/icons/shield.svg" class="img" alt="">
            </div>
            <div class="txt-div">
              <div class="title-2">Password reset was successful!</div>
              <div class="txt-content">
                Your account is now secure, you can sign in using your new password
              </div>
            </div>
          </div>
          <div class="lower-div">     
            <div class="btn" (click)="redirectUser()"  >
              <app-button label="Go to Sign in"></app-button>
            </div>
          </div>
        </div>    
      }
      <div class="footer">
        <span class="copyright">
            (c) {{currentYear}} - ELSRT
        </span>
        <span class="copyright pointer" routerLink="/policy">
            Privacy Policy
        </span>
      </div>
  
    </div>
    <div class="rite-side">
        <img src="../../../assets/icons/Rectangle1-bg.svg" alt="" class="first-top imgbg">
        <img src="../../../assets/icons/Rectangle2-bg.svg" alt="" class="second-top imgbg">
    </div>
  </div>