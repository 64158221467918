<div class="signin-container">
    <div class="login-section">
        <img src="../../../assets/icons/Rectangle1-bg.svg" alt="" class="first-top left-imgbg">
        <img src="../../../assets/icons/Rectangle2-bg.svg" alt="" class="second-top left-imgbg">
        <div class="logo">
            <div class="logo-left" routerLink="/dashboard">
                <img class="logoimg" src="../../../assets/images/logo.png" alt="">
                <span class="logoletter">LSRT</span>
            </div>
            <div class="logo-right">
                <img src="../../../assets/images/certified.png" alt="">
            </div>
        </div>

        @if (!successfull) {
        <div class="form">
            <div class="welcome">
                <div class="welcometitle-div">
                    <div class="welcomelogo">
                        <img src="../../../../assets/icons/x-with-background.svg" alt="">
                    </div>
                    <span class="welcometitle">
                        Delete your account?
                    </span>
                </div>
                <div class="welcomenotificationdiv">
                    <span class="welcomenotification">We’re sorry to see you go!</span>
                </div>
                <span class="welcomedetail">
                    If you’re certain about deleting your account, please fill out some information and click the button
                    below to proceed with your request.
                </span>
            </div>
            <div class="form-input">
                <div class="input-field">
                    <app-input-fields [control]="formData.controls.email" (fieldData)="getMail($event)" type="email"
                        placeholder="Email address" errorMessageName="email">
                    </app-input-fields>
                </div>

                <div class="input-field">
                    <app-input-fields [control]="formData.controls.password" (fieldData)="getPassword($event)"
                        type="password" placeholder="Password">
                    </app-input-fields>
                </div>


                <div class="input-field">
                    <app-input-fields [control]="formData.controls.Reason" (fieldData)="getReason($event)"
                        type="textarea" placeholder="Reason For Deleting">
                    </app-input-fields>
                    <div *ngIf="formData.controls.Reason.invalid && formData.controls.Reason.touched">
                        <small class="errorMessage">
                            <span>
                                <img src="../../../assets/icons/alert-circle.svg" alt="">
                            </span>
                            <span>
                                {{ getErrorMessage('Reason') }}
                            </span>
                        </small>
                    </div>
                </div>

                <div class="button">
                    <app-button-wrapper (click)="deleteUserAccount()" type="PRIMARY" widthsize="100%">
                        <div class="button-detail" id="content">
                            <span class="btn-name">Delete Account</span>
                            <img src="../../../../assets/icons/arrow-circle-right-send-request.svg" alt="">
                        </div>
                    </app-button-wrapper>
                </div>
            </div>
            <span routerLink="/dashboard" class="backToSigin  pointer">Go back to Sign in</span>

        </div>
        }@else {
        <div class="form">
            <div class="success-welcome">
                <div class="success-welcometitle-div">
                    <div class="success-welcomelogo">
                        <img src="../../../assets/icons/mail.svg" alt="">
                    </div>
                    <span class="success-welcometitle">
                        Request Successfully Submitted
                    </span>
                </div>
                <span class="success-welcomedetail">
                    We’ve received your account deletion request. Our Support Team will contact you shortly to confirm
                    the details and ensure you have all the assistance you need.
                </span>
                <div class="success-welcomenotificationdiv">
                    <span class="success-welcomenotification">
                        <h2 class="success-welcomenotification-title">Next steps</h2>

                        Watch for an email from our Support Team.
                        They’ll be in touch soon.
                    </span>
                </div>
            </div>
            <span routerLink="/dashboard" class="backToSigin  pointer">Go back to Sign in</span>

        </div>
        }
        <div class="footer">
            <span class="copyright">
                (c) {{copyrightYear | date:"yyyy"}} - ELSRT
            </span>
        </div>

    </div>
    <div class="info-section">
        <img src="../../../assets/icons/Rectangle1-bg.svg" alt="" class="first-top imgbg">
        <img src="../../../assets/icons/Rectangle2-bg.svg" alt="" class="second-top imgbg">

    </div>
</div>