import { Component, OnInit } from '@angular/core';
import { InputFieldsComponent } from '../../components/input-fields/input-fields.component';
import { ButtonComponent } from '../../components/button/button.component';
import { ResetPasswordDTO } from '../../dto/signin-dto';
import { AuthService } from '../../services/auth/auth.service';
import { HttpErrorResponse } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-set-password',
  standalone: true,
  imports: [CommonModule, InputFieldsComponent, RouterModule, ButtonComponent],
  templateUrl: './set-password.component.html',
  styleUrls: ['./set-password.component.scss']
})
export class SetPasswordComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private authService:AuthService, 
    private router: Router,
    private fb:FormBuilder
  ){}
  ngOnInit(): void {
    this.route.params.subscribe(params=>{
      this.token = params['token'];
    })
    
  }
  public token: string = "";
  public password:string = "";
  public confirmPassword:string = "";
  public userRole:string = "";
  public currentYear = new Date().getFullYear()
  public disableBtn:boolean = false;
  public isPswdMatch:boolean = true;

  resetPswd = this.fb.group({
    password: ['', [Validators.required, Validators.minLength(8)]],
    confirmPassword: ['', [Validators.required, Validators.minLength(8)]]
})
  getData(value:string, name:string){
    if(name =='password'){
      this.password = value;
      this.disableBtn = true;
    }else{
      this.confirmPassword = value;
    }
  }
  public step_1:boolean = true;
  public step_2:boolean = false;

  submitData(){
    this.resetPswd.markAllAsTouched()
    let payload:ResetPasswordDTO = new ResetPasswordDTO();
    if(this.resetPswd.valid){
      payload = {
        newPassword:this.password,
        token: this.token
      }
    }
    
    if(this.password !== this.confirmPassword){
      this.isPswdMatch = false;
    }else{
      this.isPswdMatch = true;
      this.authService.resetPassword(payload).subscribe({
        next:(data:any)=>{
          this.userRole = data.userType;
          this.step_1 = !this.step_1;
          this.step_2 = !this.step_2;
  
        }, 
        error:(err)=>{
          this.errorHandler(err)
        }
      })
    }
  }

  redirectUser(){
      this.router.navigate(['/signin'])
  }

  errorHandler(error: HttpErrorResponse) {
    this.authService.errorHandler(error);
  }
  
}
