import { Component } from '@angular/core';
import { ButtonComponent } from "../button/button.component";
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth/auth.service';
@Component({
  selector: 'app-page-not-found',
  standalone: true,
  imports: [ButtonComponent],
  templateUrl: './page-not-found.component.html',
  styleUrl: './page-not-found.component.scss'
})
export class PageNotFoundComponent {

  constructor(
    private router:Router,
    private authService:AuthService
  ){}
  public date = new Date().getFullYear();

  redirect(){
    console.log('redirect')
    if(this.authService.isLoggedIn()){
    console.log('login')
      
      this.router.navigate(['/dashboard'])
    }else{
    console.log('rno')

      this.router.navigate(['/signin'])
    }
  }

}
